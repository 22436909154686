
import { computed, ComputedRef, defineComponent, reactive } from 'vue';
import Button from '@/components/Button/Button.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputTextArea from '@/components/Input/TextArea/InputTextArea.vue';
import Icon from '@/components/Icon/Icon.vue';
import { validateEmail } from '@/utils/EmailValidation';

export default defineComponent({
  name: 'About',
  components: {
    Button,
    InputText,
    InputTextArea,
    Icon,
  },
  setup() {
    const contactForm: Record<string, string | undefined> = reactive({
      name: undefined,
      surnames: undefined,
      email: undefined,
      subject: undefined,
      message: undefined,
    });
    const contactFormError: ComputedRef<boolean> = computed(() => {
      console.log('name', isEmptyOrUndefined(contactForm.name));
      return isEmptyOrUndefined(contactForm.name) || isEmptyOrUndefined(contactForm.surnames)
        || isEmptyOrUndefined(contactForm.email) || isEmptyOrUndefined(contactForm.subject)
        || isEmptyOrUndefined(contactForm.message) || !validateEmail(contactForm.email);
    });

    function submitContact() {
      if (!contactFormError.value) {
        console.log(contactForm);
        alert('Gracias, En breve contestaremos a su consulta');

        contactForm.name = undefined;
        contactForm.surnames = undefined;
        contactForm.email = undefined;
        contactForm.subject = undefined;
        contactForm.message = undefined;
      }
    }

    function isEmpty(value: string | undefined): boolean {
      return value !== undefined ? value.length === 0 : false;
    }

    function isEmptyOrUndefined(value: string | undefined): boolean {
      return value === undefined || value.length === 0;
    }

    function validateEmailForm(email: string | undefined) {
      return email === undefined || (!isEmpty(email) && validateEmail(email));
    }

    return {
      contactForm,
      submitContact,
      isEmpty,
      validateEmailForm,
      contactFormError,
    };
  },
});
